export default {
  init() {
    // JavaScript to be fired on all pages
    jQuery(document).ready(function ($) {

      $('.mobile-nav-button').click(function () {
        if ($('.mobile-nav-button').hasClass('open')) {
          $('html').removeClass('nav-open');
          $(this).removeClass('open');
          $('.mobile-view').removeClass('open');
        } else {
          $('html').addClass('nav-open');
          $(this).addClass('open');
          $('.mobile-view').addClass('open');
        }
      });

      $('.search-btn').click(function () {
        if ($('.mobile-search').hasClass('open')) {
          $('.mobile-search').removeClass('open');
          $('.mobile-search-container').removeClass('open');

          if (!$('.mobile-nav-button').hasClass('open')) {
            $('html').removeClass('nav-open');
          }
        } else {
          $('.mobile-search').addClass('open');
          $('.mobile-search-container').addClass('open');
          $('html').addClass('nav-open');
          $('#search-form').focus();
        }
      });

      $('#header .menu-item-has-children > a').after('<span></span>');
      $('#header .sub-menu .menu-item-has-children > span').hide();
      $('#header .menu-item-has-children span').on('click', function () {
        $(this).toggleClass('is-open').siblings('.sub-menu')
          .toggleClass('is-open');
      });

      $(document).click(function (e) {
        $('.menu-item-has-children')
          .not($('.menu-item-has-children').has($(e.target)))
          .find('.is-open')
          .removeClass('is-open');
      });
    });

    let lastScrollTop = 0;
    let utilityNavHeight = $('#utility-nav').outerHeight();
    let headerHeight = $('#header').outerHeight();
    let alertBarHeight = 0;

    if ($('.pum-theme-top-bar')[0]) {
      alertBarHeight = $('.pum-theme-top-bar .pum-container').outerHeight();
      headerHeight = (headerHeight + alertBarHeight) - utilityNavHeight;

      $('.pum-close').on('click', function () {
        utilityNavHeight = $('#utility-nav').outerHeight();
        headerHeight = $('#header').outerHeight();
        alertBarHeight = 0;

        $('#header').css({ 'padding-top': alertBarHeight + 'px' });
      });
    }

    $('#header').css({ 'padding-top': alertBarHeight + 'px' });

    function stickyNav() {
      let st = $(window).scrollTop();
      headerHeight = $('#header').outerHeight();
      utilityNavHeight = $('#utility-nav').outerHeight();
      if ($('.pum-theme-top-bar')[0]) {
        alertBarHeight = $('.pum-theme-top-bar .pum-container').outerHeight();
        headerHeight = (headerHeight + alertBarHeight) - utilityNavHeight;
      }
      $('#header').css({ 'padding-top': alertBarHeight + 'px' });
      // If scrolling down
      if (st > lastScrollTop) {
        // If scrolled past utility nav
        $('#header').addClass('down-scroll').removeClass('up-scroll');
        if ($(window).width() > 1190) {
          if (st > utilityNavHeight) {
            $('#header').addClass('scrolled').css({ 'top': '-' + utilityNavHeight + 'px', 'transition': 'top 0.5s ease' });
            $('main').addClass('scrolled').css({ 'margin-top': headerHeight + 'px' });
          }
        } else {
          if (st > 0) {
            $('#header').addClass('scrolled').css({ 'top': '0' });
            $('main').addClass('scrolled').css({ 'margin-top': headerHeight + 'px' });
          }
        }
      }
      // If scrolled back to show utility nav
      else if (st <= 0) {
        $('#header').removeClass('scrolled down-scroll up-scroll').css({ 'transition': 'none', 'top': 'unset' });
        $('main').removeClass('scrolled').css({ 'margin-top': '0' });
      }
      // If scrolling up
      else {
        $('#header').removeClass('down-scroll').addClass('up-scroll').css({ 'top': '0' });
      }
      lastScrollTop = st;
    }

    $(window).scroll(function () {
      stickyNav();
    });

    $(window).resize(function () {
      utilityNavHeight = $('#utility-nav').outerHeight();
      headerHeight = $('#header').outerHeight();

      if ($('.pum-theme-top-bar')[0]) {
        alertBarHeight = $('.pum-theme-top-bar .pum-container').outerHeight();
        headerHeight = (headerHeight + alertBarHeight) - utilityNavHeight;
      }

      $('#header').css({ 'padding-top': alertBarHeight + 'px' });
      stickyNav();
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
